.project-type-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  min-width: 80%;
  max-width: 80%;
  height: 550px;
}

/* *SOW */
/* +Main Container */
.sow-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
  min-width: 100% !important;
  height: fit-content !important;
  min-height: fit-content !important;
  max-height: 500px !important;
}
/* +SUB CONTAINER */
.sow-checkboxes {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 5px 0;
  padding: 0 !important;
  min-width: 95%;
  max-width: 95%;
  max-height: 600px;
}

/* + Heading & HR */
#sow-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 5px 0;
  width: 100%;
  gap: 25px;
  border-top: 4px solid var(--outline-color);
  border-bottom: 4px solid var(--outline-color);
}

#sow-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  text-align: center;
  align-self: center !important;
  justify-self: center !important;
  color: var(--main-header-color);
  font-size: 30px;
  font-weight: var(--default-font-weight);
  text-shadow: var(--header-text-shadow);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
}

/* + CHECKBOX */
.sow-form-control-root {
  pointer-events: none !important;
  padding: 0;
}

.sow-form-control-label {
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  pointer-events: none !important;
  user-select: none !important;
  font-weight: var(--default-font-weight) !important;
  max-width: fit-content !important;
}

.sow-checkbox.sow-checkbox-root {
  box-shadow: none;
}

.sow-checkbox.sow-checkbox-root #sow {
  pointer-events: all;
  box-shadow: none;
}

.sow-checkbox.sow-checkbox-root {
  padding: 0px;
  margin: 0px 5px 0px 0px;
  box-shadow: none;
}

.sow-checkbox-root.Mui-disabled:hover {
  cursor: not-allowed;
}

.sow-checkbox.sow-checkbox-root .sow-unchecked-icon {
  background-color: var(--input-white-bg);
  outline: 1px solid var(--input-white-bg);
  border: none;
  border-radius: 3px;
  fill: rgba(244, 244, 244);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 2px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px,
    rgba(0, 0, 0, 0.09) 0px 6px 5px;
}

.sow-checkbox.sow-checkbox-root .sow-checked-icon {
  fill: var(--btn-green);
  background-color: var(--input-white-bg);
  outline: 1px solid var(--btn-green);
  border: none;
  border-radius: 3px;
  box-shadow: var(--radio-box-shadow);
}

/* + ADD OTHER SOW */
#add-sow-container {
  display: flex;
  flex-direction: column;
  align-self: stretch !important;
  justify-content: space-between;
  margin: 5px 0px 0px 0px;
  padding: 0;
  width: 250px;
  max-width: 270px;
  height: 65px;
  position: relative;
  left: 10px;
}

#new-sow-form-control {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  height: 32px;
  margin-bottom: 10px;
  max-width: 95% !important;
  min-width: 85% !important;
}

.sow-field-root {
  width: 100% !important;
  height: 30px;
}

.sow-notchedOutline {
  border: none !important;
  outline: none !important;
}

.sow-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.sow-field-input#newSOW-field {
  height: inherit;
  margin: 0px;
  padding: 0px;
  border-radius: 5px;
  font-size: 14px;
  color: var(--input-text-color);
  text-indent: 6px;
  font-family: var(--varela-font);
  font-weight: var(--default-font-weight);
  pointer-events: all !important;
}

.sow-field-input#newSOW-field[disabled]:hover {
  cursor: not-allowed !important;
}

#new-sow-label {
  color: var(--input-label-color) !important;
  font-family: var(--default-font) !important;
  font-size: 16px;
  font-weight: var(--default-font-weight) !important;
  transform: translate(10px, 5px) scale(1) !important;
}

#new-sow-label.sow-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(4px, -17px) scale(0.82) !important;
}

.add-sow-btn#add-sow-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 14px !important;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  height: 25px !important;
  width: 40px !important;
  padding: 0 2px 2px 2px !important;
  margin: 0px !important;
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  border-radius: 4px !important;
  border: none !important;
  pointer-events: all;
}

.add-sow-btn#add-sow-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer !important;
}

.add-sow-btn#add-sow-btn[disabled] {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
}

.add-sow-btn#add-sow-btn[disabled]:hover {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

@media screen and (width < 1450px) {
  .project-type-container {
    min-width: 750px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sow-checkboxes {
    min-width: 100%;
    height: 480px;
  }
  #add-sow-container {
    width: 250px;
    margin-left: 3px;
  }
  #new-sow-form-control {
    width: 250px;
    height: 30px;
  }
  .sow-field-root {
    width: 100%;
    height: 30px;
  }
  .sow-field-input#newSOW-field {
    height: inherit;
    font-size: 13px;
  }
  #new-sow-label {
    font-size: 15px;
    transform: translate(10px, 4px) scale(1) !important;
  }
  #sow-checkbox-label.sow-form-control-root {
    margin: 10px 0px 15px 15px;
  }

  #sow-checkbox-label .sow-form-control-label {
    font-size: 16px;
    margin-left: 8px;
  }

  .sow-checkbox.sow-checkbox-root .sow-unchecked-icon,
  .sow-checkbox.sow-checkbox-root .sow-checked-icon {
    height: 22px;
    width: 22px;
  }
  #sow-info-circ {
    height: 30px;
    width: 30px;
  }
}

@media screen and (1450px <= width < 1650px) {
  .project-type-container {
    width: 800px;
  }
  .sow-checkboxes {
    width: 100%;
    height: 500px;
  }
  #sow-checkbox-label.sow-form-control-root {
    margin: 10px 5px 15px 25px;
  }

  #sow-checkbox-label .sow-form-control-label {
    font-size: 15px;
    margin-left: 8px;
  }

  .sow-checkbox.sow-checkbox-root .sow-unchecked-icon,
  .sow-checkbox.sow-checkbox-root .sow-checked-icon {
    height: 21px;
    width: 21px;
  }

  #add-sow-container {
    width: 240px;
    margin-left: 13px;
  }
  #new-sow-form-control {
    width: 240px;
    height: 32px;
  }
  .sow-field-root {
    width: 100%;
    height: 32px;
  }
  .sow-field-input#newSOW-field {
    height: inherit;
    font-size: 15px;
  }
  #new-sow-label {
    font-size: 15px;
    transform: translate(10px, 6px) scale(1) !important;
  }
  #sow-info-circ {
    height: 30px;
    width: 30px;
  }
}

@media screen and (1650px <= width < 1750px) {
  .project-type-container {
    width: 820px;
    margin: 15px 0;
  }

  #sow-info-circ {
    height: 32px;
    width: 32px;
  }

  #sow-checkbox-label.sow-form-control-root {
    margin: 10px 0 15px 15px;
  }

  #sow-checkbox-label .sow-form-control-label {
    font-size: 16px;
    margin-left: 4px;
  }

  .sow-checkbox.sow-checkbox-root .sow-unchecked-icon,
  .sow-checkbox.sow-checkbox-root .sow-checked-icon {
    height: 22px;
    width: 22px;
  }

  #add-sow-container {
    width: 290px;
  }
  #new-sow-form-control {
    width: 290px;
    height: 32px;
  }
  .sow-field-root {
    width: 100%;
    height: 32px;
  }

  .sow-field-input#newSOW-field {
    height: inherit;
    font-size: 15px;
  }

  #new-sow-label {
    font-size: 15px;
    transform: translate(10px, 5px) scale(1) !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  .project-type-container {
    width: 840px;
    margin: 15px 0;
  }

  #sow-info-circ {
    height: 30px !important;
    width: 30px !important;
  }

  .sow-checkboxes {
    width: 100%;
    /* height: 500px; */
  }

  #sow-checkbox-label.sow-form-control-root {
    margin: 10px 10px 15px 20px;
  }

  #sow-checkbox-label .sow-form-control-label {
    font-size: 18px;
    margin-left: 10px;
  }

  .sow-checkbox.sow-checkbox-root .sow-unchecked-icon,
  .sow-checkbox.sow-checkbox-root .sow-checked-icon {
    height: 24px;
    width: 24px;
  }

  #add-sow-container {
    /* width: 300px; */
    /* margin-left: 10px; */
    left: 20px;
    height: 70px;
  }
  #new-sow-form-control {
    max-width: 240px;
    height: 35px;
  }
  .sow-field-root {
    width: 100%;
    height: 35px;
  }
  .sow-field-input#newSOW-field {
    height: inherit;
    font-size: 16px;
  }

  #new-sow-label {
    font-size: 16px;
    transform: translate(10px, 6px) scale(1) !important;
  }
}

@media screen and (2150px <= width) {
  .project-type-container {
    width: 900px;
    margin: 15px 0;
  }

  #sow-info-circ {
    height: 32px;
    width: 32px;
  }

  .sow-checkboxes {
    width: 100%;
    height: 500px;
  }

  #sow-checkbox-label.sow-form-control-root {
    margin: 10px 10px 15px 20px;
  }

  #sow-checkbox-label .sow-form-control-label {
    font-size: 19px;
    margin-left: 10px;
  }

  .sow-checkbox.sow-checkbox-root .sow-unchecked-icon,
  .sow-checkbox.sow-checkbox-root .sow-checked-icon {
    height: 25px;
    width: 25px;
  }

  #add-sow-container {
    width: 310px;
    margin-left: 10px;
    height: 70px;
  }
  #new-sow-form-control {
    width: 290px;
    height: 35px;
  }
  .sow-field-root {
    width: 100%;
    height: 35px;
  }
  .sow-field-input#newSOW-field {
    height: inherit;
    font-size: 16px;
  }

  #new-sow-label {
    font-size: 16px;
    transform: translate(10px, 6px) scale(1) !important;
  }
}
