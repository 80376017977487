.colors-legend-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--main-blue);
  border-top: 4px solid var(--outline-color);
  justify-self: flex-start;
  margin: 0px;
  padding: 5px 0;
}

.colors-legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  pointer-events: auto;
}

.colors-legend.revise {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 4px;
  pointer-events: auto;
}

.colors {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
  pointer-events: all;
  gap: 15px;
}

.color-label {
  font-family: var(--default-font);
  font-size: 16px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
  cursor: default;
  color: var(--argus-white);
  font-weight: var(--font-weight-bold);
}

.legend-icon {
  width: 14px;
  height: 14px;
  margin: 0 !important;
  padding: 0 !important;
}

.legend-icon#icon-Current {
  fill: rgb(49, 190, 255);
  height: 20px;
  width: 20px;
}

.legend-icon#icon-Completed {
  fill: rgb(7, 244, 31);
  height: 20px;
  width: 20px;
}

.legend-icon#icon-Incomplete {
  fill: rgb(224, 12, 12);
  height: 20px;
  width: 20px;
}

.legend-icon#icon-Revisions {
  fill: rgb(224, 12, 12);
  height: 20px;
  width: 20px;
}

.legend-icon#icon-Revised {
  fill: rgb(255, 136, 0);
  height: 20px;
  width: 20px;
}

#completed-dot,
#current-slide-dot,
#incomplete-dot,
#completed-dot-revise,
#revisions-dot {
  display: flex;
  justify-content: space-between;
}

@media screen and (width < 1450px) {
  .colors-legend-container {
    border-width: 3px;
  }

  .colors {
    gap: 10px;
  }

  .color-label {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
  }

  .color-circle {
    width: 13px;
    height: 13px;
  }

  #not-started-dot .color-circle {
    height: 9px;
    width: 9px;
  }
}

@media screen and (1450px <= width < 1580px) {
  .color-label {
    font-size: 13px;
    gap: 10px;
  }

  .color-circle {
    width: 13px;
    height: 13px;
  }
}

@media screen and (1580px <= width < 1635px) {
  .colors {
    gap: 10px;
  }

  .color-label {
    font-size: 13px;
  }

  .legend-icon {
    width: 18px !important;
    height: 18px !important;
  }
}

@media screen and (1635px <= width < 1850px) {
  .color-label {
    font-size: 15px;
  }

  .legend-icon {
    width: 20px !important;
    height: 20px !important;
  }
}

@media screen and (1850px <= width) {
  .colors {
    gap: 12px;
  }
  .color-label {
    font-size: 15px;
  }

  .legend-icon {
    width: 20px !important;
    height: 20px !important;
  }
}
