.form-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 45px;
  padding: 5px 0;
  margin: 5px 0 0 0;
  transition: all 0.35s cubic-bezier(0.25, 1, 0.5, 1);
}

#back-btn {
  background-color: var(--btn-light-blue);
  color: var(--btn-white-text) !important;
  width: 120px;
  font-size: 17px;
  font-family: var(--default-font);
  letter-spacing: 0.25px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: 5px;
  transition: all 0.35s ease-in-out !important;
}

#back-btn:hover {
  background-color: var(--btn-light-blue-hover);
  cursor: pointer;
}

#next-btn {
  background-color: var(--btn-green);
  color: var(--btn-white-text) !important;
  font-family: var(--default-font);
  letter-spacing: 0.25px;
  height: 40px;
  width: 120px;
  font-size: 17px;
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: 5px;
  transition: all 0.35s ease-in-out !important;
}

#next-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer;
}

#submit-btn {
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  height: 40px;
  width: 120px;
  font-size: 17px;
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: 5px;
  transition: all 0.25s ease-in-out !important;
}

#submit-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer;
}

#disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  box-shadow: none !important;
  height: 35px;
  width: 75px;
  font-size: 17px;
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: 5px;
  cursor: not-allowed !important;
  pointer-events: auto !important;
  transition: all 0.35s ease-in-out !important;
}

/* #submit-btn:disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  box-shadow: none !important;
  height: 40px;
  width: 110px;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: 5px;
  cursor: not-allowed !important;
  pointer-events: auto !important;
} */

#disabled:hover {
  cursor: not-allowed !important;
}

#submit-check-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(79, 79, 79, 0.371);
}

.submit-check-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background-color: rgb(255, 218, 183);
  outline: 3px solid rgb(242, 89, 0);
  max-width: 70%;
  min-width: 800px;
  width: fit-content;
  height: 220px;
  border-radius: 5px;
}

.submit-check-modal-content:focus-visible {
  outline: none;
}

#check-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
  width: 100%;
}

#check-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

#submit-check-header {
  font-family: var(--default-font);
  font-size: 28px;
  font-weight: var(--font-weight-bold);
  color: rgb(242, 89, 0);
  margin: 10px 0 0 0 !important;
  padding: 0;
}

#submit-form-check-hr {
  width: 100%;
  padding: 0;
  margin: 0 !important;
  border: none;
  border-bottom: 4px solid rgb(242, 89, 0);
}

#submit-check-message {
  font-family: var(--default-font);
  font-size: 18px;
  font-weight: var(--default-font-weight);
  color: rgb(242, 89, 0);
  margin: 0 0 auto 0 !important;
  padding: 0;
  max-width: 780px;
  text-align: center;
}

.modal-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 0 0 15px 0 !important;
}

#close-modal-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-red);
  font-size: 15px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  height: 35px;
}

#close-modal-btn:hover {
  background-color: var(--btn-red-hover);
}

#submit-modal-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-green);
  font-size: 15px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  height: 35px;
}

#submit-modal-btn:hover {
  background-color: var(--btn-green-hover);
}

@media screen and (width < 1450px) {
  .form-btns {
    gap: 25px;
  }

  #back-btn,
  #next-btn,
  #submit-btn,
  #disabled {
    width: 92px;
    font-size: 13px;
    height: 32px;
  }
}

@media screen and (1450px <= width < 1750px) {
  #back-btn,
  #next-btn,
  #submit-btn,
  #disabled {
    width: 100px;
    font-size: 14px;
    height: 35px;
  }
}

@media screen and (1750px <= width < 2150px) {
  #back-btn,
  #next-btn,
  #submit-btn,
  #disabled {
    width: 100px;
    font-size: 14px;
    height: 35px;
  }
}
