.measure-file {
  position: absolute;
  top: 0px;
  left: 543px;
  width: 473px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  height: 700px;
  pointer-events: none;
  z-index: 9999;
}
