/* Main Container */
/* -FLEX BOX */
/* #main-form-container {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  align-self: center;
  min-width: max(100vw, 100%);
  width: 100%;
  height: calc(100vh - 160px);
  height: auto;
  transition: all 1.2s ease 0s;
  padding-bottom: 10px !important;
  overflow: visible;
  position: relative;
  top: 55px;
} */

/* -GRID */
#main-form-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-areas: "timeline-container form-container-col";
  gap: 25px;
  min-width: 100vw !important;
  width: 100vw;
  min-height: calc(100vh - 162px);
  height: auto;
  padding: 0 0 0 0 !important;
  overflow: visible;
  position: relative;
  top: 55px;
  scroll-behavior: smooth;
}

#main-form-container.hide-timeline {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "form-container-col";
}

/* Column 2 */
.form-container-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 162px);
  height: inherit;
}

/* Slides Container */
#form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-self: center !important;
  height: fit-content;
  padding: 0 0 10px 0;
  text-align: center;
  outline: 4px solid var(--outline-color);
  background-color: var(--main-blue);
  border-radius: 2px;
  box-shadow: var(--input-container-box-shadow);
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
  margin: 20px 0 10px 0;
  max-width: 75%;
  min-width: calc(max(75%, 950px));
}

#main-form-container.hide-timeline #form-container {
  max-width: 60%;
  min-width: calc(max(60%, 850px));
}

.form-slides {
  /* For all pages */
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin: 5px 0px;
  padding: 0;
  max-width: 100%;
  min-width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  box-sizing: border-box;
}

/* | Outer Timeline Container */
/* Column 1 */
.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: flex-start !important;
  min-height: calc(100vh - 160px);
  max-height: auto;
  min-width: 320px;
}

#main-form-container.hide-timeline .timeline-container {
  display: none;
  width: 0;
}

/*  % Inner Timeline Container */
.timeline {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center !important;
  justify-content: flex-start !important;
  position: sticky;
  min-width: 300px;
  top: 180px;
  height: auto;
  width: 92%;
  border-radius: 3px;
  padding: 0;
  pointer-events: all;
  outline: 4px solid var(--outline-color);
  box-shadow: rgba(0, 0, 0, 0.287) 0px 2px 9px 2px;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

#main-form-container.hide-timeline .timeline-container .timeline {
  display: none;
  width: 0;
}

/* ~ timeline header */
#timeline-header {
  margin: 0;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: 1px 1.5px 2px rgba(0, 0, 0, 0.3);
  background-color: var(--main-blue);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

#form-container > .MuiTablePagination-displayedRows {
  font-size: 16px;
  color: rgb(232, 232, 232);
}

.form-container-p {
  text-align: center;
  align-self: center;
  font-size: clamp(14px, 1rem, 16px);
  line-height: 1.1 !important;
  max-width: 95% !important;
  min-width: 95% !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.form-container-p#intro-p {
  display: flex;
  align-self: center !important;
  justify-self: center !important;
  font-size: clamp(12px, 1rem, 14px);
  min-width: 90% !important;
  max-width: 90% !important;
  text-shadow: none;
  color: var(--p-white);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  pointer-events: none;
  user-select: none;
}

#general-info-p {
  color: var(--p-white);
  margin: 10px 0;
  text-shadow: none;
  font-family: var(--default-font);
  font-weight: 500;
  pointer-events: none;
  user-select: none;
  max-width: 88% !important;
  min-width: 88% !important;
  width: 88% !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#general-info-bold {
  text-decoration: underline;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.important-text {
  align-self: center;
  font-size: clamp(14px, 1rem, 17px) !important;
  font-weight: var(--default-font-weight);
  text-shadow: none;
  font-family: var(--default-font);
  line-height: 1;
  pointer-events: none;
  user-select: none;
  color: var(--argus-black-text) !important;
  /* color: var(--p-yellow) !important; */
  max-width: 95%;
  min-width: 95%;
  text-shadow: none !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
  margin: 8px 0;
}

.question-section {
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  color: var(--p-yellow);
  margin: 0 0 5px 0;
  transition: all 0.35 cubic-bezier(0.25, 1, 0.5, 1) !important;
  font-size: clamp(17px, 1.1rem, 20px);
}

input:focus {
  outline: none;
}

.additional-info {
  min-width: 95%;
  max-width: 95%;
  background-color: var(--input-white-bg);
  color: var(--input-text-color);
  border-radius: 5px;
  resize: none;
  align-self: center;
  height: 100px;
  outline: none;
  border: none;
  box-shadow: var(--input-box-shadow);
  font-size: clamp(14px, 1rem, 16px);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  padding: 4px 0;
  text-indent: 8px;
  transition: all 0.35s cubic-bezier(0.25, 1, 0.5, 1);
}

.form-label {
  font-size: 20px;
  font-weight: 550 !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.form-container-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 4px solid var(--outline-color);
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.form-container-heading {
  color: var(--main-header-color);
  font-size: 38px;
  font-weight: 800;
  text-shadow: 2px 3px 3px rgba(55, 55, 55, 0.3);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.75, 1) !important;
}

.form-container-p {
  color: var(--p-white);
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  max-width: 92% !important;
  font-family: var(--default-font);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.75, 1) !important;
}

.info-circ:hover {
  cursor: pointer;
}

.submit-new-client {
  font-size: 18px;
  font-weight: var(--default-font-weight);
  color: var(--p-white);
  background-color: rgb(79, 203, 30);
  margin: 10px 0;
}

.submit-new-client:hover {
  background-color: rgb(62, 191, 11);
  cursor: pointer;
}

#paginationProp .MuiTablePagination-displayedRows {
  color: rgb(232, 232, 232);
  font-size: 14px;
  padding: 0px;
}

@media screen and (width < 1275px) {
  .form-container-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-width: 100vw;
  }
  .form-container-header {
    max-height: 45px;
    min-height: 40px;
    border-width: 3px;
  }

  .form-container-heading {
    font-size: 24px;
    line-height: 1;
  }
}

/* !MEDIA QUERY */
@media screen and (1275px <= width < 1450px) {
  .timeline {
    outline: 3px solid var(--outline-color);
  }

  .form-container-header {
    max-height: 45px;
    min-height: 40px;
    border-width: 3px;
  }

  .form-container-heading {
    font-size: 26px;
    line-height: 1;
  }
}

@media screen and (1450px <= width < 1580px) {
  .form-container-p {
    scale: 1;
    align-self: center;
  }
  .form-label {
    font-size: 22px !important;
  }
  .form-label-file {
    font-size: 18px !important;
  }
  .form-container-header {
    max-height: 50px;
    min-height: 45px;
  }

  .form-container-heading {
    font-size: 30px;
    line-height: 1;
  }
}

@media screen and (1580px <= width < 1635px) {
  .form-container-header {
    max-height: 55px;
    min-height: 50px;
  }

  .form-container-heading {
    font-size: 32px;
    line-height: 1;
  }
}

@media screen and (1635px <= width < 1750px) {
  .form-container-header {
    max-height: 55px;
    min-height: 50px;
  }

  .form-container-heading {
    font-size: 32px;
    line-height: 1;
  }
}

@media screen and (1450px <= width < 1750px) {
  .form-container-header {
    max-height: 60px;
    min-height: 55px;
  }

  .form-container-heading {
    font-size: 30px;
    line-height: 1;
  }
}

/* LARGE */
@media screen and (1750px <= width < 2150px) {
  .form-container-p {
    scale: 1.1;
    align-self: center;
  }

  .form-container-header {
    max-height: 55px;
    min-height: 50px;
  }

  .form-container-heading {
    font-size: 30px;
    line-height: 1;
  }
  .info-circ {
    height: 30px;
    width: 30px;
  }
  .form-label-file {
    font-size: 22px !important;
  }
}

@media screen and (2150px <= width) {
  .form-container-p {
    font-size: 20px;
    align-self: center;
  }
}
