#additionalInformation {
  min-width: 95%;
  max-width: 95%;
  margin-bottom: 25px;
  padding-left: 4px;
  padding-right: 2px;
  padding-top: 5px;
  text-indent: 5px;
  align-self: center !important;
}

#additionalInformation:disabled {
  cursor: not-allowed;
}

#add-h1 {
  color: rgba(243, 243, 255, 0.989);
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 5px;
  text-shadow: 2px 3px 3px rgba(55, 55, 55, 0.3);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
  margin: 10px 0px;
}

.add-hr {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 100%;
  margin: 15px 0px !important;
  padding: 0 !important;
  border-top: 3px solid rgb(232, 232, 232);
}

#add-p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(243, 243, 255, 0.989);
  font-size: 18px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
}

@media screen and (width < 1450px) {
  #additionalInformation {
    height: 200px !important;
  }
}

@media screen and (1450px <= width < 1750px) {
  #additionalInformation {
    height: 200px !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  #additionalInformation {
    height: 200px !important;
  }
}

@media screen and (2150px <= width) {
  #additionalInformation {
    height: 260px !important;
  }
}
