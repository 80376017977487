/* - Header - */
.timeline-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  border-bottom: 3px solid var(--outline-color);
  background-color: var(--main-blue);
}

.timeline-header #timeline-heading {
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  font-size: 18px;
  letter-spacing: 0.75px;
}

/* - Content Container */
.timeline-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 2px 0;
  background-color: var(--timeline-bg);
}

/* ~ Timeline Item */
.timeline-item {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: fit-content;
}

.column1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 7px 0 0 0;
  height: 100%;
}

.column3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px 0 0 0;
  height: 100%;
}

/* $ Timeline Item Text */
/* Text Alignment for Alternating Labels */
.timeline-item-text {
  font-family: var(--default-font);
  font-size: 14px;
  font-weight: var(--default-font-weight);
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.timeline-item-text.left-text {
  text-align: right;
}

.timeline-item-text.right-text {
  text-align: left;
}

/* Adjust spacing to align labels and separators */
.timeline-item-text.left-text,
.timeline-item-text.right-text {
  display: flex;
  align-self: start;
}
.timeline-item-text.active-text {
  color: rgb(27, 179, 255);
}

.timeline-item-text.complete-text {
  color: rgb(10, 248, 34);
}

.timeline-item:hover .timeline-item-text.complete-text {
  color: rgb(0, 176, 18);
}

.timeline-item-text.incomplete-text,
.timeline-item-text.reviseSlide-text {
  color: rgb(255, 29, 29);
}

.timeline-item:hover .timeline-item-text.incomplete-text,
.timeline-item:hover .timeline-item-text.reviseSlide-text {
  color: rgb(194, 11, 11);
}

.timeline-item-text.editedSlide-text {
  color: rgb(255, 152, 34);
}

.timeline-item:hover .timeline-item-text.editedSlide-text {
  color: rgb(228, 122, 0);
}

/* $ Timeline Separator */
.timeline-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;
  padding-top: 2px;
}

.timeline-item.last-slide-item .timeline-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
}

/* > Timeline Dot container */

/*
active-dot: rgb(58, 189, 255)
complete-dot: rgb(7, 244, 31)
incomplete-dot: rgb(255, 52, 52)
*/

/* * Timeline Icon */
.timeline-icon {
  margin: 0 !important;
  padding: 0 !important;
  transition: all 0s ease 0s !important;
}

.timeline-icon.active-icon {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  fill: rgb(27, 179, 255);
}

.timeline-icon.complete-icon {
  height: 18px;
  width: 18px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  fill: rgb(7, 244, 31);
  transition: all 0 ease 0;
}
.timeline-item:hover {
  cursor: pointer;
}

.timeline-item:hover .timeline-icon.complete-icon {
  fill: rgb(0, 176, 18);
}

.timeline-icon.incomplete-icon,
.timeline-icon.reviseSlide-icon {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  fill: rgb(255, 29, 29);
}

.timeline-item:hover .timeline-icon.incomplete-icon,
.timeline-item:hover .timeline-icon.reviseSlide-icon {
  fill: rgb(194, 11, 11);
}

.timeline-icon.editedSlide-icon {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  fill: rgb(255, 152, 34);
}

.timeline-item:hover .timeline-icon.editedSlide-icon {
  fill: rgb(228, 122, 0);
}

.timeline-dot.active-dot {
  background-color: rgb(226, 226, 226);
  border-radius: 0;
}

.timeline-item:hover .timeline-dot.active-dot {
  background-color: rgb(27, 179, 255);
}

/* * Timeline Connector */
.timeline-connector {
  height: 18px;
  width: 1px;
  margin: 0;
}

.timeline-connector.complete-line {
  background-color: rgb(7, 244, 31);
}

.timeline-item:hover .timeline-connector.complete-line {
  background-color: rgb(0, 176, 18);
}

.timeline-connector.incomplete-line,
.timeline-connector.reviseSlide-line {
  background-color: rgb(244, 20, 20);
}

.timeline-item:hover .timeline-connector.incomplete-line,
.timeline-item:hover .timeline-connector.reviseSlide-line {
  background-color: rgb(173, 3, 3);
}

.timeline-connector.editedSlide-line {
  background-color: rgb(255, 152, 34);
}

.timeline-item:hover .timeline-connector.editedSlide-line {
  background-color: rgb(228, 122, 0);
}

@media screen and (width < 1450px) {
  .timeline-item-text {
    font-size: 13px;
    font-weight: 500;
  }

  .timeline-icon {
    height: 15px !important;
    width: 15px !important;
  }
}

@media screen and (1450px <= width < 1750px) {
  .timeline-item-text {
    font-size: 13px;
    font-weight: 600;
  }
  .timeline-icon {
    height: 16px !important;
    width: 16px !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  .timeline-item {
    gap: 8px;
  }
  .timeline-item-text {
    font-size: 15px;
    font-weight: 600;
  }

  .timeline-dot {
    height: 12px;
    width: 12px;
  }

  .timeline-connector {
    width: 2px;
  }
}
