.dataGridRoot {
  margin: 0 15px 15px 15px;
  border-color: var(--outline-color);
  background-color: var(--main-blue);
  color: rgb(243, 243, 243) !important;
  font-family: var(--default-font);
}

.columnHeaders {
  background-color: var(--argus-blue-dark) !important;
}

.columnHeader {
  display: flex;
  flex-direction: row !important;
}

.renderZone .row .cell {
  border-right: 1px solid #34618d48 !important;
}

.columnHeadersInner .columnHeader {
  border-right: 1px solid #223b534b !important;
}

.gridContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 95%;
  margin: 0 0 15px 0;
  padding: 0;
}

.dataGridRoot {
  width: 95%;
}

.gridHeader {
  color: var(--main-header-color);
  font-family: var(--default-font);
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-shadow: var(--header-text-shadow);
}

.p {
  display: flex;
  color: var(--p-white);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 18px;
  width: 80%;
  padding: 0px;
  justify-self: center !important;
  text-shadow: none;
}

/* - Column Headers Styles */
.columnHeaderDraggableContainer {
  display: flex;
  justify-content: center !important;
  height: 60px;
  justify-self: center !important;
  align-items: center;
  margin-right: 0;
}

.columnHeaderTitleContainer .columnHeaderTitle {
  white-space: pre-wrap;
  line-height: normal;
  text-align: center;
  width: max-content;
  padding: 0px 5px 0px 5px !important;
  font-weight: var(--default-font-weight);
  font-size: 22px;
  text-shadow: 0px 1px 1px rgb(65, 65, 65);
}

.pinnedColumns {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 2px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: inherit !important;
}

.pinnedColumnHeaders {
  background-color: inherit !important;
  border-right: none !important;
}

.projectNameHeader .columnHeaderTitleContainer {
  max-width: fit-content;
  justify-self: center !important;
  justify-content: space-between !important;
}

.timeStampHeader .columnHeaderTitleContainer {
  max-width: fit-content;
  justify-self: center !important;
  justify-content: space-between !important;
}

.columnHeaderCheckbox .columnHeaderTitleContainer {
  display: none !important;
}

/* - Rows */
.row:focus,
.row:focus-within,
.row:focus-visible,
.row:hover,
.row.MuiHovered {
  outline: none;
  background-color: transparent !important;
}

/* - Cells */
.cell {
  color: white;
  font-size: 18px;
  overflow: ellipsis;
  border-bottom: 1px solid white !important;
  padding: 0px;
  margin: 0px;
}

.cell:focus,
.cell:focus-within,
.cell:focus-visible,
.cell:hover {
  outline: none;
}

.timeStampCells .cellContent {
  color: rgb(242, 242, 242);
  font-weight: bold;
  font-family: var(--default-font);
  letter-spacing: 0.5px;
  font-size: 18px;
}

.projectNameCells .cellContent {
  font-weight: bold;
  text-shadow: 0px 1px 1px rgb(92, 92, 92);
  text-align: center;
  position: relative;
  right: 0;
  white-space: pre-wrap;
  font-size: 18px;
  letter-spacing: 0.5px;
}

/* - Complete Button - */
.completeBtn {
  background-color: #4fcb1e !important;
  color: rgb(240, 240, 240);
  height: 35px;
  width: 75px !important;
  font-size: 16px !important;
  font-weight: var(--default-font-weight) !important;
}

.completeBtn:hover {
  background-color: #3eb60b !important;
}

/* - Sort Icon & Separator */
.columnSeparator {
  display: none !important;
}

.sortIcon {
  color: white;
  opacity: 1;
  visibility: visible !important;
  stroke: white;
  max-width: 20px !important;
  width: min-content !important;
  z-index: 99999 !important;
  margin: 0px !important;
  padding: 0px !important;
}

.columnHeader:target(.columnHeaderSorted) .sortIcon {
  visibility: visible !important;
  opacity: 1 !important;
  stroke: white;
}

.columnHeader:not(.columnHeaderSorted) .sortIcon {
  visibility: visible !important;
  opacity: 0.8 !important;
  stroke: rgb(239, 239, 239);
}

.columnHeaderSortable:hover .sortIcon {
  visibility: visible !important;
  opacity: 1 !important;
  stroke: white;
  color: white;
}

.iconButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible !important;
  opacity: 1 !important;
  margin: 0px;
  padding: 0px !important;
  width: min-content !important;
}

.iconButtonContainer:hover .sortIcon {
  color: white;
  opacity: 1;
}

/* - Pagination - */
.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  max-height: 40px !important;
  min-height: 30px !important;
  background-color: var(--argus-blue-dark) !important;
}

.MuiTablePagination-displayedRows {
  color: var(--label-color) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  width: max-content;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

.MuiTablePagination-select {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 5px 0px 5px;
  padding: 0px 5px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
}

.MuiTablePagination-select:focus {
  background-color: transparent !important;
}

.MuiTablePagination-selectLabel {
  margin: 0px 0px 0px 5px;
  width: fit-content;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  color: var(--label-color) !important;
  letter-spacing: 0.45px;
}

.MuiTablePagination-toolbar .MuiInputBase-root {
  width: 45px !important;
  margin: 0px;
  padding: 0px !important;
  text-align: center;
}

.MuiToolbar-root .MuiToolbar-gutters {
  margin: 0px !important;
  padding: 0px !important;
  width: 0px !important;
}

.MuiTablePagination-selectIcon {
  color: var(--label-color) !important;
}

.intro-pagination .MuiPaginationItem-text {
  color: var(--label-color);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
}

.intro-pagination .MuiPaginationItem-page.Mui-selected {
  background-color: rgba(27, 79, 105, 0.25) !important;
}

.MuiTablePagination-spacer {
  display: none !important;
}

/* | SELECT CLIENT COMPONENT */
#clientSelectForm {
  display: flex;
  align-self: center;
  justify-content: center !important;
  align-items: center !important;
  width: 350px;
  height: 40px;
  margin: 15px 0;
  background-color: var(--input-white-bg);
  border-radius: 5px;
  box-shadow: var(--input-box-shadow);
  border: none;
  outline: none;
}

#clientSelect::before {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.clientSelectRoot::before,
.clientSelectRoot::after {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.clientSelectRoot {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 5px;
  border: none !important;
  outline: none !important;
}

.clientSelectRoot:focus,
.clientSelectRoot:focus-within,
.clientSelectRoot:hover {
  outline: none !important;
  border: none !important;
}

#clientSelect {
  display: flex !important;
  justify-self: center !important;
  align-self: center !important;
  align-items: center !important;
  text-indent: 8px;
  margin: 0 !important;
  padding: 0 !important;
  height: 40px;
  min-width: 40px;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  font-family: var(--default-font);
  font-size: 18px;
  color: var(--input-text-color) !important;
  font-weight: var(--default-font-weight);
}

#clientSelect:focus,
#clientSelect:focus-within,
#clientSelect:hover {
  outline: none !important;
  border: none !important;
}

.clientSelectNativeInput,
.clientSelectNativeInput:focus,
.clientSelectNativeInput:focus-within {
  border: none !important;
  outline: none !important;
}

#clientSelectLabel {
  font-family: var(--default-font);
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight);
  transform: translate(14px, 10px) scale(1);
}

#clientSelectLabel.labelShrink {
  transform: translate(3px, -16px) scale(0.78);
  color: var(--shrink-label-color) !important;
}

.clientSelectIcon {
  border-radius: 50%;
  margin-right: 5px;
  color: rgb(125, 125, 125) !important;
}

.clientSelectPaper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--argus-blue-light) !important;
}

.clientSelectList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start !important;
  align-self: center;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  background-color: transparent;
  border: 2px solid rgba(233, 233, 233, 0.957);
}

.clientLIDivider {
  border-bottom: 2px solid rgba(233, 233, 233, 0.957) !important;
}

#menuClearIcon {
  color: red;
  margin-left: 8px;
}

#clearMenuItems.clientLI {
  font-size: 18px;
  font-weight: 500;
  color: rgb(233, 233, 233);
  background-color: rgb(75, 75, 75);
}

.clientLI {
  width: 100%;
  color: rgba(233, 233, 233, 0.957);
  font-weight: var(--default-font-weight);
  font-size: 18px;
}

.clientLI:hover {
  background-color: var(--argus-blue-dark) !important;
  color: white !important;
}

.clientLISelected {
  background-color: var(--argus-blue-dark) !important;
  color: white !important;
}

.clientLI {
  padding: 8px 0px;
}

.menuItemIntro {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (1450px <= width < 1750px) {
  .gridContainer {
    min-width: 900px;
  }
}
