#myDeqInstructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myDeq-p {
  margin: 5px 0;
  font-size: 18px;
  width: 90%;
  color: rgb(236, 236, 236);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
}

#recipient-info {
  font-family: var(--default-font);
  font-size: 18px;
  color: var(--p-yellow);
  font-weight: var(--default-font-weight);
  width: 90%;
  align-self: center;
}

#sign-option-p {
  margin: 10px 0;
  font-size: 18px;
  color: var(--p-yellow);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
}

#myDeqOL {
  margin: 0px;
}

.myDeq-li {
  color: rgb(234, 234, 234) !important;
  padding: 5px 0;
  font-size: 20px !important;
  text-indent: 5px;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  text-align: left;
}

.myDeq-li::marker {
  color: rgb(234, 234, 234);
  font-size: 20px;
  font-family: var(--default-font);
}

#cdx-span {
  position: relative;
  font-size: 20px;
  font-weight: var(--default-font-weight);
  color: rgb(235, 235, 235);
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: var(--form-label-text-color);
}

#cdx-link,
#cdx-link:visited {
  font-size: 20px;
  font-weight: var(--default-font-weight);
  color: rgb(0, 0, 255);
  text-decoration: underline;
  text-decoration-color: rgb(18, 18, 255);
  width: fit-content;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 0.5px 0.5px 1px rgba(4, 18, 69, 0.3);
}

#cdx-link:hover,
#cdx-link:active {
  color: rgb(0, 0, 209);
  text-decoration-color: rgb(0, 0, 209);
  text-decoration: underline;
}

.inline-checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  width: fit-content;
  gap: 45px;
}

#swppp-signs {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin: 5px 0 25px 0;
  width: 100%;
}

.sign-hr {
  margin: 0 !important;
  padding: 0 !important;
  border-bottom: 2px solid var(--outline-color) !important;
  width: 100%;
}

/* Buttons to add and remove recipients */
#recipient-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  position: relative;
  padding: 0px;
  margin: 0 0 15px 0;
}

#add-recipient {
  border-radius: 5px;
  background-color: rgb(79, 203, 30);
  color: white;
  font-weight: var(--default-font-weight);
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
}

#add-recipient:hover {
  background-color: rgb(50, 180, 11);
}

#add-recipient:disabled {
  background-color: #92929287 !important;
  color: rgba(255, 255, 255, 0.656) !important;
  pointer-events: auto;
}

#remove-recipient {
  border-radius: 5px;
  background-color: rgb(209, 11, 11);
  color: white;
  font-weight: var(--default-font-weight);
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
}

#remove-recipient:hover {
  background-color: rgb(180, 11, 11);
}

#remove-recipient:disabled {
  border-radius: 5px;
  background-color: #92929287;
  color: rgba(255, 255, 255, 0.656);
  pointer-events: auto;
}

#remove-recipient:disabled:hover,
#add-recipient:disabled:hover {
  cursor: not-allowed !important;
}

@media screen and (width < 1450px) {
  #recipient-btns {
    width: 156px;
    right: 184px;
  }

  #recipient-info {
    font-size: 16px;
    width: 96%;
  }

  #add-recipient,
  #remove-recipient {
    font-size: 12px;
  }

  #add-recipient {
    width: 50px;
    padding: 5px;
  }

  #remove-recipient {
    width: 70px;
    padding: 5px;
  }
}

@media screen and (1450px <= width < 1750px) {
  #recipient-btns {
    width: 186px;
    right: 177px;
  }

  #add-recipient,
  #remove-recipient {
    font-size: 13px;
  }

  #add-recipient {
    width: 55px;
    padding: 5px;
  }

  #remove-recipient {
    width: 75px;
    padding: 5px;
  }
}

@media screen and (1750px <= width < 2150px) {
  #recipient-btns {
    width: 206px;
    right: 194.5px;
  }
}

@media screen and (2150px <= width) {
  #recipient-btns {
    width: 206px;
    right: 256.5px;
  }
}
