.calculator-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  width: 100%;
  position: absolute;
  top: 75px;
}

.calc-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: min-content;
}

#unit-label {
  font-size: 16px;
  color: var(--p-white);
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  border: none;
  text-align: center;
  border-radius: 3px;
  margin: 0;
}

#input-value {
  min-width: 70px;
  max-width: 110px;
  height: 24px;
  padding: 0;
  background-color: transparent;
  font-size: 15px;
  color: var(--p-white);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  text-decoration: none;
  border: none;
  border-bottom: 2px solid var(--outline-color);
}

.unit-select:focus,
.unit-select:active,
.unit-select:focus-visible,
.unit-select:focus-within {
  outline: none !important;
  border: none !important;
}

.acreage-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--p-white);
  font-size: 16px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  margin: 0;
}
