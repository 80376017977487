#general-info-p {
  color: white;
  text-shadow: none;
  font-weight: 500;
  font-family: var(--default-font);
  line-height: 1.2;
  pointer-events: none;
  user-select: none;
}

#general-info-check {
  color: white;
  text-shadow: none;
  font-weight: 550;
  font-family: var(--default-font);
  line-height: 1.2;
  pointer-events: none;
  user-select: none;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

#general-info-bold {
  color: var(--p-yellow) !important;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
}

.general-info {
  color: var(--p-yellow);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 18px;
  width: 96%;
  text-align: center;
  align-self: center;
}

@media screen and (width < 1450px) {
  #general-info-p,
  #general-info-bold {
    font-size: 15px;
  }
}
